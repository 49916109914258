/* eslint-disable no-underscore-dangle */

import { Container } from 'src/components/Container/Container'
import { Headline } from 'src/components/Headline/Headline'
import { EntrypointCard } from 'src/components/HomepageEntrypoints/EntrypointCard'
import { EntrypointTextCard } from 'src/components/HomepageEntrypoints/EntrypointTextCard'
import { Text } from 'src/components/Text/Text'

import styles from './EntrypointRow.module.scss'

import type {
  EntrypointCard as EntrypointCardInterface,
  EntrypointRow as EntrypointRowInterface,
  EntrypointTextCard as EntrypointTextCardInterface,
} from 'src/types/HomepageContent'

interface EntrypointRowProps extends EntrypointRowInterface {}

export const EntrypointRow = ({ cardsCollection: { items }, rowTitle, rowSubtitle }: EntrypointRowProps) => (
  <Container>
    <div className={styles.textContainer}>
      {rowTitle && <Headline variant="h2">{rowTitle}</Headline>}
      {rowSubtitle && <Text>{rowSubtitle}</Text>}
    </div>
    <div className={styles.cardContainer}>
      {items.map((card) => {
        const isSoloCard = items.length === 1

        return card.__typename === 'EntryPointCard' ? (
          <EntrypointCard {...(card as EntrypointCardInterface)} key={card.cardTitle} isSoloCard={isSoloCard} />
        ) : (
          <EntrypointTextCard {...(card as EntrypointTextCardInterface)} key={card.cardTitle} />
        )
      })}
    </div>
  </Container>
)
