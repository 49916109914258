import dynamic from 'next/dynamic'

import { EntrypointRow } from 'src/components/HomepageEntrypoints/EntrypointRow'

import type { ComponentType } from 'react'
import type { EntrypointCardCarouselProps } from 'src/components/HomepageEntrypoints/EntrypointCardCarousel'
import type { EnrichedHomepageContentResponse, EnrichedProductCarousel } from 'src/types/HomepageContent'

const ProductsCarousel: ComponentType<EnrichedProductCarousel> = dynamic(() =>
  import('src/components/HomepageEntrypoints/ProductsCarousel').then((mod) => mod.ProductsCarousel),
)

const EntrypointCardCarousel: ComponentType<EntrypointCardCarouselProps> = dynamic(() =>
  import('src/components/HomepageEntrypoints/EntrypointCardCarousel').then((mod) => mod.EntrypointCardCarousel),
)

export interface HomepageEntrypointsProps {
  entrypointsData: EnrichedHomepageContentResponse
}

export const HomepageEntrypoints = ({
  entrypointsData: {
    homePageEntryRows: { firstRow, coffeeProductsCarousel, thirdRow, gearProductsCarousel, fifthRow },
  },
}: HomepageEntrypointsProps) => (
  <section>
    <EntrypointRow {...firstRow} />
    <ProductsCarousel {...coffeeProductsCarousel} />
    <EntrypointRow {...thirdRow} />
    <ProductsCarousel {...gearProductsCarousel} />
    <EntrypointCardCarousel {...fifthRow} />
  </section>
)
