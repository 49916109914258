import clsx from 'clsx'

import { Button } from 'src/components/Button/Button'
import { Headline } from 'src/components/Headline/Headline'
import { Image } from 'src/components/Image/Image'
import { config } from 'src/config'

import styles from './EntrypointCard.module.scss'

import type { EntrypointCard as EntrypointCardType } from 'src/types/HomepageContent'

export interface EntrypointCardProps extends EntrypointCardType {
  isSoloCard?: boolean
}

export const EntrypointCard = ({
  cardTitle,
  cardImageId,
  primaryButton,
  secondaryButton,
  isCardDoubleSize = false,
  isSoloCard = false,
}: EntrypointCardProps) => (
  <section className={clsx(styles.card, isCardDoubleSize && styles.isDouble, isSoloCard && styles.isFullWidth)}>
    <Image
      identifier={cardImageId}
      alt={cardTitle}
      srcSet={!isSoloCard ? [600, 800] : [1200]}
      sizes={`(min-width: ${config.breakpoints.lg}px) 25vw, (min-width: ${config.breakpoints.sm}px) 50vw, 200px`} // low resolution image on mobile for SEO
      width={isSoloCard ? '800' : '400'}
      height="400"
      background
      cover
    />
    <div className={styles.content}>
      <Headline variant="h2" className={styles.headline}>
        {cardTitle}
      </Headline>
      <div className={styles.buttonsContainer}>
        {primaryButton && (
          <Button type="button" href={primaryButton.buttonLink}>
            {primaryButton.buttonText}
          </Button>
        )}
        {secondaryButton && (
          <Button type="button" href={secondaryButton.buttonLink} variant="secondary" inverted>
            {secondaryButton.buttonText}
          </Button>
        )}
      </div>
    </div>
  </section>
)
