import styles from './EntrypointTextCard.module.scss'

import type { EntrypointTextCard as EntrypointTextCardInterface } from 'src/types/HomepageContent'

interface EntrypointTextCardProps extends EntrypointTextCardInterface {}

export const EntrypointTextCard = ({ cardTitle, backgroundColorCode, textColorCode }: EntrypointTextCardProps) => (
  <div className={styles.card} style={{ backgroundColor: `#${backgroundColorCode}`, color: `#${textColorCode}` }}>
    <div className={styles.text}>{cardTitle}</div>
  </div>
)
